import { apiInstance, apiSBCInstance, apiUkrChatInstance } from '../index';

const AuthAPI = {
  loginYellowSchool: async (body) => {
    return apiInstance.post('auth/login/', body);
  },

  loginSBC: async (body) => {
    return apiSBCInstance.post('auth/login/', body);
  },

  loginUkrChat: async (body) => {
    return apiUkrChatInstance.post('auth/login/', body);
  },
};

export default AuthAPI;
