import axios from 'axios';
import { baseUrlBloggers, baseUrlUkrChat, baseUrlYellow } from '../config';
import { store } from '../redux/store';
import { getCurrentUser, setCurrentUser } from '../helpers/Utils';

export const apiInstance = axios.create({
  baseURL: `${baseUrlYellow}/api/`,
  timeout: 60000,
});

export const apiSBCInstance = axios.create({
  baseURL: `${baseUrlBloggers}/`,
  timeout: 60000,
});

export const apiUkrChatInstance = axios.create({
  baseURL: `${baseUrlUkrChat}/`,
  timeout: 60000,
});

const setAuth = (instance) =>
  instance.interceptors.request.use(
    function (config) {
      const rootStorage = JSON.parse(localStorage.getItem('persist:root') || '{}');
      const authData = JSON.parse(rootStorage.authUser || '{}');
      const accessToken = authData?.currentUser?.token;

      if (accessToken) {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Bearer ${accessToken}`;
      }

      return config;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

const setLogout = (instance, base) => {
  instance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      if (error.response.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        try {
          const refresh = JSON.parse(
            JSON.parse(localStorage.getItem('persist:root') || '{}')
              ?.authUser || '{}'
          )?.currentUser?.refresh;

          const {
            data: { accessToken, refreshToken },
          } = await axios.post(
            `${base}${base.includes('api') ? '' : '/api'}/auth/refresh`,
            {
              refreshToken: refresh,
            }
          );

          // Save new tokens to localStorage
          const userJson = JSON.parse(
            localStorage.getItem('persist:root') || '{}'
          );
          const user = JSON.parse(userJson.authUser || '{}');
          user.currentUser.token = accessToken;
          user.currentUser.refresh = refreshToken;
          userJson.authUser = JSON.stringify(user);
          localStorage.setItem('persist:root', JSON.stringify(userJson));

          setCurrentUser({
            ...getCurrentUser(),
            token: accessToken,
            refresh: refreshToken,
          });

          // Update token in original request and retry
          originalRequest.headers.Authorization = `Bearer ${accessToken}`;
          return instance(originalRequest);
        } catch (err) {
          // Refresh also failed - logout user
          store.dispatch({
            type: 'LOGOUT_USER',
            payload: { history: err.config.history },
          });
        }
      }
      return Promise.reject(error);
    }
  );
};

setAuth(apiInstance);
setAuth(apiSBCInstance);
setAuth(apiUkrChatInstance);

setLogout(apiInstance, baseUrlYellow);
setLogout(apiSBCInstance, baseUrlBloggers);
setLogout(apiUkrChatInstance, baseUrlUkrChat);

console.log(JSON.parse(localStorage.getItem('persist:root')));
